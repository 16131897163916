import Vue from 'vue'
import App from './App.vue'
import VueResource from 'vue-resource'
import VueRouter from 'vue-router'
import Reviews from './components/Reviews.vue'
import Company from './components/Company.vue'
import About from './components/About.vue'

// ENABLE USE 3RD PARTY PLUGINS
Vue.use(VueResource);
Vue.use(VueRouter);

// ENABLE SINGLE PAGE APP ROUTING
const routes = [{
    path: '/',
    component: Company
  },
  {
    path: '/about',
    component: About
  },
  {
    path: '/reviews/:id',
    component: Reviews
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
})

// INIT APP
new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
