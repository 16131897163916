<template>

  <div class="router">
    <div class="container about-container">
      <img src="../assets/together.jpg" alt="about us image"/>
      <p class="about-text">
        This site has been thoughtfully put together by Eirene Cremations.<br/><br/>We're on a mission to help Canadians find the most affordable and transparent cremations. <br/><br/>We know that navigating funerals, cremations, and everything in between can be tough - and you don't have to do it alone with the worry of being inundated with choices. <br/><br/>Our goal is to provide Canadians with the best and most unbiased information and options available to make the choices they need for their loved one's end-of-life.
        Have questions, issues or concerns? <a href="mailto:hello@eirene.ca">Contact us today for more information.</a>
      </p>
    </div>
  </div>

</template>

<script>

  export default {}

</script>

<style>

  .about-container {
    display: grid;
    grid-template-columns: 20rem auto;
    grid-gap: 2rem;
    align-items: center;
   
  }
  .about-text {
    color: #282b2e !important;
    line-height: 1.8;
    font-size: 1.3rem;
  }
  .about-container img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
  }
  @media (max-width: 768px) {
    .about-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 2rem;
      align-items: center;
    }
  }

</style>
