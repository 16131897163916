<template>

    <div class="router">
        <div class="container" v-if="company">
            <div class="company-info">
                <div class="company-card-image-container">
                    <img v-if="company.imageURL" :src="company.imageURL" alt="company logo"/>
                    <img v-else src="../assets/company.jpg" alt="company logo"/>
                    <p v-if="company.featured" class="featured">Featured</p>
                </div>
                <div class="company-text-info">
                    <h2 class="name">{{ company.name }}</h2>
                    <h4 class="company-location mt-1">{{company.location.join(', ') }}</h4>
                    <h4 class="company-description mt-1" v-if="company.description">{{ company.description }}</h4>
                    <h4 class="mt-1">Total {{ company.reviews.length }} Reviews</h4>
                    <div class="c-rating c-rating--big mt-1" v-bind:data-rating-value="company.averageRating">
                        <button>1</button>
                        <button>2</button>
                        <button>3</button>
                        <button>4</button>
                        <button>5</button>
                        <h4> ( Average: {{ company.averageRating }} star )</h4>
                    </div>
                </div>
                <div class="hr-info"></div>
                <h4 class="review-anouncement">{{ company.name }} Reviews ( Total {{ company.reviews.length }} Reviews
                    )</h4>
            </div>

            <div class="reviews">
                <div class="review-card" v-for="review in company.reviews" v-bind:key="review.created">
                    <div class="reviewer">
                        <img v-if="review.reviewerImageUrl" :src="review.reviewerImageUrl" class="avatar"
                             alt="reviewer image"/>
                        <img v-else src="../assets/person.svg" alt="person avatar" class="avatar"/>
                        <div class="reviewer-info">
                            <p class="reviewer-name">{{ review.reviewerName }}</p>
                            <p>at: {{ review.created }}</p>
                        </div>
                    </div>
                    <div class="review-details">
                        <div class="star">
                            <img src="../assets/star.svg" alt="star" v-for="i in review.rating" v-bind:key="i"/>
                        </div>
                        <p class="review-details-comment">
                            <a :href="review.fullUrl">{{ review.review }}</a>
                        </p>
                        <br/>
                      <p>
                        <a :href="review.fullUrl" style="color:#d32323">Read more on Yelp.com</a>
                      </p>
                    </div>
                    <hr class="review-hr">
                </div>
            </div>
        </div>
        <div v-else class="loader">Loading...</div>

    </div>

</template>

<script>

    export default {
        data() {
            return {
                company: null
            }
        },
        created() {
            this.$http
                .get(
                    'https://us-central1-funeralhomereviews-fe416.cloudfunctions.net/getDetailsForHome?id=' + this.$route.params.id
                )
                .then(
                    response => response.json(),
                    error => console.log(error)
                )
                .then(
                    json => (this.company = json),
                    error => console.log(error)
                )
        }
    }

</script>

<style>

    .company-info {
        display: grid;
        grid-template-columns: 20rem auto;
        grid-column-gap: 2rem;
        grid-row-gap: 1rem;
    }

    .company-card-image-container {
        width: 100%;
        position: relative;
    }

    .company-card-image-container img {
        width: 100%;
        border-radius: 0.3rem;
    }

    .featured {
        padding: 0.3rem 0.5rem;
        background-color: #35393d;
        color: white;
        font-size: 0.7rem;
        font-weight: 900;
        display: inline-block;
        border-radius: 0.3rem;
        text-transform: uppercase;
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }

    .company-text-info {
        display: flex !important;
        flex-direction: column;
    }

    .name {
        color: #0c447e;
        font-size: 2rem;
        margin: 0;
    }

    .company-description {
        letter-spacing: 0.5px;
        line-height: 1.5rem;
        margin-bottom: 0.5rem;
    }

    .mt-1 {
        margin-top: 0.5rem;
        font-size: 1.2rem;
    }

    .company-review-count {
        margin-top: auto;
    }

    .review-anouncement {
        grid-column: 1 / span 2;
    }

    .hr-info {
        grid-column: 1 / span 2;
        height: 2px;
        background-color: #0c447e;
    }

    .reviews {
        margin-bottom: 1rem;
    }

    .review-card {
        display: grid;
        grid-template-columns: 20rem auto;
        grid-column-gap: 2rem;
        grid-row-gap: 1rem;
        margin-top: 1.5rem;
    }

    .reviewer {
        display: flex;
        align-items: flex-start;
    }

    .reviewer-info {
        margin-left: 1rem;
    }

    .reviewer-name {
        font-family: 'Lora', serif;
        font-size: 1.2rem;
        font-weight: 900;
        margin-bottom: 0.5rem;
    }

    .avatar {
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 50%;
    }

    .review-hr {
        grid-column: 1 / span 2;
        height: 1px;
        background-color: #35393d;
        opacity: 0.5;
    }

    .review-details-comment {
        line-height: 1.6rem;
    }

    .star {
        margin-bottom: 0.5rem;
    }

    .star img {
        margin-right: 0.2rem;
    }

    /* hiding company card last hr */
    .review-card:last-child > .review-hr {
        display: none;
    }

    @media (max-width: 768px) {
        .company-info {
            display: flex;
            flex-direction: column;
        }

        .hr-info {
            margin: 1rem 0;
        }

        .review-card {
            display: block !important;
        }

        .star {
            margin-top: 0.5rem;
        }

        .reviewer {
            align-items: center;
        }

        .review-details-details {
            margin-bottom: 1rem;
        }

        .review-hr {
            margin-top: 1rem;
        }
    }

    @media (max-width: 1400px) {
        .container {
            max-width: 1200px;
        }
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1300px;
        }
    }

    /* Loader animation */
    .loader,
    .loader:before,
    .loader:after {
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation: load7 1.8s infinite ease-in-out;
        animation: load7 1.8s infinite ease-in-out;
    }

    .loader {
        color: #0d447e;
        margin: 30px auto;
        margin-bottom: 100px;
        position: relative;
        text-indent: -9999em;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }

    .loader:before,
    .loader:after {
        content: '';
        position: absolute;
        top: 0;
    }

    .loader:before {
        left: -3.5em;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    .loader:after {
        left: 3.5em;
    }

    @-webkit-keyframes load7 {
        0%,
        80%,
        100% {
            box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
            box-shadow: 0 2.5em 0 0;
        }
    }

    @keyframes load7 {
        0%,
        80%,
        100% {
            box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
            box-shadow: 0 2.5em 0 0;
        }
    }

</style>
*-
